import React, { useEffect, useRef, useState } from "react";
import LocationIcon from "../assets/icons/location-black.png"
import { getDataAPI } from "../services/api";
import { limitCharacter } from "../services/helper";
import BgVideo from "../components/bg_video";

function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

export default function BannerEnergy() {
    const [data, setData] = useState(null);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);

    const backgroundVideoRef = useRef()

    const loadData = () => {
        getDataAPI({ type: "Energy", building: localStorage.getItem("building") }, (res) => {
            setData(res)
            backgroundVideoRef.current.src = res.energy.energy_main_background
        }, (err) => {
            console.log(err)
        })
    }
    const adjustDateTime = () => {
        let ddate = new Date();
        setHour(pad(ddate.getHours()))
        setMinute(pad(ddate.getMinutes()))
    }
    useEffect(() => {
        loadData()
        const interval = setInterval(loadData, 3600000);
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        adjustDateTime()
        const interval = setInterval(adjustDateTime, 60000);
        return () => clearInterval(interval)
    }, [])

    return (
        <>
            {hour && <div class="d-flex overflow-hidden" style={{
                width: "1920px",
                height: "1080px",
            }}>
                <div id="banner" class="container-fluid bg-black" style={{ color: "#ffffff" }}>
                    <div class="row" style={{ height: "840px" }}>
                        <video ref={backgroundVideoRef} class="banner-video-bg" autoPlay loop muted width="1920px">
                            <source src="" type="video/mp4" />
                        </video>
                        <div class="row w-100 d-plex justify-content-between" style={{ height: "840px", textAlign: "start", paddingLeft: "2rem", position: "absolute" }}>
                            <h1 class="banner-temp-energy" >{data?.energy?.temp}°</h1>
                            <div class="w-100 d-flex flex-column justify-content-center">
                                {limitCharacter(data?.energy?.energy_message, 24).map(line => <h1 class="banner-message w-100" style={{ paddingLeft: "30%" }} >{line}</h1>)}
                            </div>
                            <div class="w-100 d-flex justify-content-between mt-auto" style={{ height: "6rem" }}>
                                <div class="d-flex justify-content-center" style={{ fontSize: "5rem", lineHeight: "5rem" }}>{hour}:{minute}</div>
                                <img alt="logo" src={data?.logo} height="64px" style={{ marginTop: "1rem" }} />
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center" style={{ height: "240px" }}>
                        <div class="col-3 gradient-custom h-100" style={{ color: "black" }}>
                            <div class="row align-items-center h-100">
                                <div>
                                    <h1 style={{ fontSize: "3.125rem", fontWeight: "600", marginTop: "1rem" }}>
                                        <img alt="" src={LocationIcon} height="50" style={{ marginBottom: "1.5rem" }} /> {data?.energy?.location}
                                    </h1>
                                    <h1 class="display-1 mt-4" style={{ fontWeight: "bold" }}>ค่าพลังงาน</h1>
                                </div>
                            </div>
                        </div>
                        <div class="col h-100 bg-dark-cyan" >
                            <div class="row align-items-center h-100">
                                <div class="col-6 p-0 m-0" >
                                    <div class="d-flex justify-content-start box-video-bg">
                                        {data?.energy?.energy_banner_1 &&
                                            <BgVideo source={data?.energy?.energy_banner_1} />
                                        }
                                        <div class="row h-100 w-100" style={{ position: "absolute", paddingLeft: "12px" }}>
                                            <div class="d-flex justify-content-start align-items-center ml-5 p-0">
                                                <div style={{ textAlign: "left", paddingLeft: "40%" }}>
                                                    {limitCharacter(data?.energy?.energy_keyword_1, 15).map(line => <h1 class="display-4" >{line}</h1>)}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6 p-0 m-0" >
                                    <div class="d-flex justify-content-start box-video-bg p-0" style={{ borderLeft: "4px solid #FFFFFF" }}>
                                        {data?.energy?.energy_banner_2 &&
                                            <BgVideo source={data?.energy?.energy_banner_2} />
                                        }
                                        <div class="row h-100 w-100" style={{ position: "absolute", paddingLeft: "12px" }}>
                                            <div class="d-flex justify-content-start align-items-center ml-5 p-0">
                                                <div style={{ textAlign: "left", paddingLeft: "40%" }}>
                                                    {limitCharacter(data?.energy?.energy_keyword_2, 15).map(line => <h1 class="display-4" >{line}</h1>)}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
import React, { useEffect, useRef, useState } from "react";
import LocationIcon from "../assets/icons/location.png"
import { calculateFontSize, limitCharacter } from "../services/helper";
import { getDataAPI } from "../services/api";
import BgVideo from "../components/bg_video";


function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

export default function BannerAQI() {
    const [data, setData] = useState([]);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);

    const outdoorVideoRef = useRef()
    const indoorVideoRef = useRef()

    const loadData = () => {
        getDataAPI({ type: "AQI", building: localStorage.getItem("building") }, (res) => {
            setData(res)
            outdoorVideoRef.current.src = res?.outdoor?.main_background
            indoorVideoRef.current.src = res?.indoor?.main_background
        }, (err) => {
            console.log(err)
        });
        
    }

    const adjustDateTime = () => {
        let ddate = new Date();
        setHour(pad(ddate.getHours()))
        setMinute(pad(ddate.getMinutes()))
    }

    useEffect(() => {
        loadData()
        const interval = setInterval(loadData, 3600000);
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        adjustDateTime()
        const interval = setInterval(adjustDateTime, 60000);
        return () => clearInterval(interval)
    }, [])

    return (
        <>
            {data && <div style={{
                width: "1920px",
                height: "1080px",
                display: "flex",
                flexDirection: "row",
                background: "#010101"
            }}>
                <div id="banner" class="container-fluid" style={{ color: "#ffffff" }}>
                    <div class="row h-100">
                        <div id="outdoor" class="p-0" style={{ width: "1080px", overflowX: "hidden" }}>
                            <div class="row" style={{ height: "840px" }}>
                                <video class="banner-video-bg" ref={outdoorVideoRef} autoPlay loop muted >
                                    <source src="" type="video/mp4" />
                                </video>
                                <div class="row" style={{ width: "60%", textAlign: "start", position: "absolute" }}>
                                    <h1 class="banner-temp" style={{ paddingLeft: "2rem" }}>{data?.outdoor?.temp}°</h1>
                                    <div style={{ marginLeft: "20%", paddingRight: "20%", paddingTop: "28vh" }}>
                                        <div class="d-flex flex-column">
                                            {limitCharacter(data?.outdoor?.message).map(line => <h1 class="banner-message">{line}</h1>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center p-0" style={{ height: "240px" }}>
                                <div class="col-3 bg-black h-100 p-0" >
                                    <div class="row align-items-center h-100 p-0">
                                        <div style={{ marginLeft: "12px" }}>
                                            <div class="d-flex justify-content-center" style={{ fontSize: "2.5rem" }}>คุณภาพอากาศ</div>
                                            <div class="d-flex justify-content-center" style={{ fontSize: "6rem" }}>{hour}:{minute}</div>                                        </div>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div class="row h-100 p-0 m-0">
                                        <div class="d-flex justify-content-start box-video-bg">
                                            {data?.outdoor?.banner &&
                                                <BgVideo source={data?.outdoor?.banner} />
                                            }
                                            <div class="row w-100 h-100 p-0" style={{ position: "absolute" }}>
                                                <div class="w-100 p-0 d-flex justify-content-center align-items-center ml-5">
                                                    <h1 class="w-100" style={{ paddingLeft: "27%", fontSize: calculateFontSize(data?.outdoor?.location)}}><img alt="" src={LocationIcon} height="40" /> {data?.outdoor?.location}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="indoor" class="p-0" style={{ width: "840px", overflowX: "hidden" }}>
                            <div class="row" style={{ height: "840px" }}>
                                <video class="banner-video-bg" ref={indoorVideoRef} autoPlay loop muted style={{ padding: 0 }} >
                                    <source src="" type="video/mp4" />
                                </video>
                                <div class="row" style={{ width: "40%", height: "15%", textAlign: "end", position: "absolute", right: 0 }}>
                                    <h1 class="banner-temp">{data?.indoor?.temp}°</h1>
                                    <div style={{ paddingRight: "2%", textAlign: "start" }}>
                                        <div class="d-flex flex-column" style={{ paddingTop: "28vh" }}>
                                            {limitCharacter(data?.indoor?.message).map(line => <h1 class="banner-message">{line}</h1>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center p-0" style={{ width: "840px", height: "240px"}}>
                                <div class="d-flex justify-content-start box-video-bg p-0" style={{ borderLeft: "4px solid #FFFFFF" }}>
                                    {data?.indoor?.banner &&
                                        <BgVideo source={data?.indoor?.banner}/>
                                    }
                                    <div class="row h-100 w-100" style={{ position: "absolute" }}>
                                        <div class="w-100 p-0 d-flex justify-content-center align-items-center ml-5">
                                            <h1 class="w-100" style={{ paddingLeft: "22%", fontSize: calculateFontSize(data?.indoor?.location) }}><img alt="" src={LocationIcon} height="40" /> {data?.indoor?.location}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
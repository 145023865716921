import React, { useEffect, useState } from "react";
import SettingAQI from "../components/setting_aqi";
import SettingEnergy from "../components/setting_energy";
import { getSetting, getUploadLink, updateSettings, uploadFile, uploadSvg } from "../services/api";
import { redirectTo } from "../services/helper";

export default function BuildingSetting() {
    const [data, setData] = useState(null);
    const [presigned, setPresigned] = useState(null);
    const [files, setFiles] = useState([]);
    const [isAlert, setIsAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    const handleSubmitSetting = () => {
        var payload = {
            building: localStorage.getItem("building"),
            outdoor_name: document.getElementById("inputOutdoor").value,
            indoor_name: document.getElementById("inputIndoor").value,
            switch_interval: document.getElementById("SwitchInterval").value,
        }
        updateSettings(payload,
            (res) => {
                setIsAlert(true)
                setAlertTitle(`update info success`)
            },
            (err) => { console.log(err) }
        )

        for (const [key, value] of Object.entries(files)) {
            if (value == undefined) continue
            if (presigned && key in presigned) {
                var formData = new FormData();
                formData.append("link", presigned[key]);
                formData.append("file", value);

                uploadSvg(formData,
                    (response) => {
                        setIsAlert(true)
                        setAlertTitle(`${response?.data}`)
                    },
                    (error) => console.log(error)
                )
            }
        }
        console.log("success")
    }
    useEffect(() => {
        if (localStorage.getItem("building")) { }
        else redirectTo("/")

        getSetting((res) => {
            document.getElementById("SwitchInterval").value = res?.switch_interval || 1
            document.getElementById("inputOutdoor").value = res?.outdoor_name || null
            document.getElementById("inputIndoor").value = res?.indoor_name || null
            setData(res)
        }, (err) => {
            console.log(err)
        })
        getUploadLink(
            (res) => {
                setPresigned(res?.data)
            }, (err) => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <div class="d-flex flex-column m-5 px-5">
                <p class="fs-2 fw-bold text-start">Building Setting</p>
                {isAlert && <div class="alert alert-warning p-1 px-3 text-capitalize" role="alert" style={{ fontSize: "0.8rem" }}>
                    {alertTitle}
                </div>}
                <form action="">
                    <div class="form-row text-start mb-4 d-flex">
                        <div class="form-group col-md-6 mb-2 px-1">
                            <label for="inputLogo">Logo</label>
                            <input type="file" class="form-control" id="inputLogo" accept="image/svg+xml"
                                onChange={(e) => {
                                    setFiles({ ...files, logo: e.target.files[0] })
                                }}
                            />
                        </div>
                        <div class="form-group col-md-6 mb-2 px-1">
                            <label for="SwitchInterval">Switch Interval (Minutes)</label>
                            <input type="number" class="form-control" id="SwitchInterval" min={1} />
                        </div>
                    </div>
                    <div class="form-row text-start mb-4 d-flex">
                        <div class="form-group col-md-6 mb-2 px-1">
                            <label for="inputOutdoor">Outdoor Location</label>
                            <input type="text" class="form-control" id="inputOutdoor" placeholder="Outdoor Location Name" />
                        </div>
                        <div class="form-group col-md-6 mb-2 px-1">
                            <label for="inputIndoor">Indoor Location</label>
                            <input type="text" class="form-control" id="inputIndoor" placeholder="Indoor Location Name" />
                        </div>
                    </div>
                </form>
                <button class="btn btn-primary" onClick={handleSubmitSetting}>Submit</button>

                {/* Energy */}
                <hr class="solid"></hr>
                <p class="fs-4 fw-bold text-start pt-3">Energy</p>
                <SettingEnergy data={data?.energy} presigned={presigned} />

                {/* AQI */}
                <hr class="solid"></hr>
                <div>
                    <p class="fs-4 fw-bold text-start pt-3">Air Quality</p>
                    <SettingAQI display_name="good" name="good" data={data} presigned={presigned} />
                    <hr class="solid"></hr>
                    <SettingAQI display_name="moderated" name="moderated" data={data} presigned={presigned} />
                    <hr class="solid"></hr>
                    <SettingAQI display_name="unhealthy for sensitive group" name="sensitive" data={data} presigned={presigned} />
                    <hr class="solid"></hr>
                    <SettingAQI display_name="unhealthy" name="unhealthy" data={data} presigned={presigned} />
                    <hr class="solid"></hr>
                    <SettingAQI display_name="very unhealthy" name="veryunhealthy" data={data} presigned={presigned} />
                    <hr class="solid"></hr>
                    <SettingAQI display_name="hazardous" name="hazardous" data={data} presigned={presigned} />
                </div>
            </div>
        </>
    )
}
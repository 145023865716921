import React, { useEffect, useState } from "react";
import LocationIcon from "../assets/icons/location-black.png"
import { getDataAPI } from "../services/api";
import BgVideo from "../components/bg_video";
import { limitCharacter } from "../services/helper";

function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

export default function BottomBarEnergy() {
    const [data, setData] = useState(null);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);

    const loadData = () => {
        getDataAPI({ type: "Energy", building: localStorage.getItem("building") }, (res) => {
            setData(res)
        }, (err) => {
            console.log(err)
        })
        let ddate = new Date();
        setHour(pad(ddate.getHours()))
        setMinute(pad(ddate.getMinutes()))
    }
    useEffect(() => {
        loadData()
        const interval = setInterval(loadData, 60000);
        return () => clearInterval(interval)
    }, [])

    return (
        <>
            {data && <div class="d-flex overflow-hidden" style={{
                width: "1080px",
                height: "200px",
            }}>
                <div id="banner" class="container-fluid " style={{ color: "#ffffff" }}>
                    <div class="row h-100" style={{ padding: 0 }}>
                        <div class="gradient-mix-blend align-items-center h-100 p-2" style={{ width: "12%" }}>
                            <h3 class="mt-3 mb-4" style={{ fontWeight: "bold" }}>ค่าพลังงาน</h3>
                            <h1 class="pt-2 mb-0">{hour}:{minute}</h1>
                            <img alt="logo" src={data?.logo} height="14px" />
                        </div>
                        <div class="bg-black align-items-center h-100 p-0" style={{ width: "44%" }}>
                            <div class="d-flex justify-content-start box-video-bg-200">
                                {data?.energy?.energy_tv_background &&
                                    <BgVideo source={data?.energy?.energy_tv_background} bottom_bar />
                                }
                                <div class="row h-100 w-100 m-0" style={{ position: "absolute", color: "#0D3133" }}>
                                    <div class="d-flex justify-content-center align-items-center m-0 mt-4 p-0 pl-2 w-100">
                                        <div>
                                            {limitCharacter(data?.energy?.energy_message, 24).map(line => <h1 class="bottombar-energy-message" style={{ paddingLeft: "8.5rem" }} >{line}</h1>)}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-baseline mt-2" style={{ height: "40px"}}>
                                        <div class="d-flex pt-2" style={{ paddingLeft: "10rem" }}>
                                            <img alt="" src={LocationIcon} height="30" />
                                            <span style={{ fontSize: "1.25rem", fontWeight: "500", paddingTop: ".4rem" }}>{data?.energy?.location}</span>
                                        </div>
                                        <span style={{ fontSize: "2rem", fontWeight: "400" }}>{data?.energy?.temp}°</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-dark-cyan p-0" style={{ width: "44%" }}>
                            <div class="row d-flex h-100 p-0 m-0">
                                <div class="col-6 p-0 m-0" >
                                    <div class="d-flex justify-content-start box-video-bg-200 w-100">
                                        {data?.energy?.energy_tv_1 &&
                                            <BgVideo source={data?.energy?.energy_tv_1} bottom_bar/>
                                        }
                                        <div class="row h-100 w-100 p-0 m-0" style={{ position: "absolute" }}>
                                            <div class="w-100 d-flex justify-content-center pt-3 align-items-start">
                                                <div class="w-100">
                                                    {limitCharacter(data?.energy?.energy_keyword_1, 15).map(line => <h3 class="w-100" >{line}</h3>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 p-0 m-0" >
                                    <div class="d-flex justify-content-start box-video-bg-200" style={{borderLeft: "1.25px solid #FFFFFF"}}>
                                        {data?.energy?.energy_tv_2 &&
                                            <BgVideo source={data?.energy?.energy_tv_2} bottom_bar/>
                                        }
                                        <div class="row h-100 w-100 p-0 m-0" style={{ position: "absolute" }}>
                                            <div class="w-100 d-flex justify-content-st pt-3 align-items-start">
                                                <div class="w-100">
                                                    {limitCharacter(data?.energy?.energy_keyword_2, 15).map(line => <h3 class="w-100" >{line}</h3>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
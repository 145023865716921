export function redirectTo(path) {
    return window.location.href = window.location.origin + path
}
export function limitCharacter(text, n) {
    if (text == undefined) return []
    if (n == 8) return text.toString().replace(/(.{8})/, "$1|").split("|");
    if (n == 24) return text.toString().replace(/(.{24})/g, "$1|").split("|");
    return text.toString().replace(/(.{15})/g, "$1|").split("|");
}
export function calculateFontSize(text) {
    if(text == null || text == undefined) return "4.5rem"
    if(text.length > 30) return "2rem"
    if(text.length > 20) return "2.5rem"
    if(text.length > 15) return "3.25rem"
    if(text.length > 12) return "3.5rem"
    return "4.5rem"
}
export function numberWithCommas(x) {
    if(x == undefined || x == null) return "0"
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
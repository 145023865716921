import axios from "axios";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import moment from "moment"

const BASE_URL = "https://smartbuilding.platform.jumpai.tech";
const API_VERSION = "1.2.1"

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "content-Type": "application/json"
  },
  credentials: "same-origin",
});

const uploadApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'content-Type': 'multipart/form-data'
  },
  credentials: "same-origin",
});

export const authApi = {
  login: (payload, callback, onRejected) => {
    // payload['token'] = TOKEN
    api
      .post("/authenticate", { ...payload, version: API_VERSION })
      .then((res) => {
        if ("id" in res?.data) {
          var expire_date = moment().add(8, "hour")
          localStorage.setItem("user_id", res.data.id)
          localStorage.setItem("username", res.data.username)
          localStorage.setItem("pwd", base64_encode('SMBD' + payload.password))
          localStorage.setItem("building", res.data.building)
          localStorage.setItem("role", res.data.role)
          localStorage.setItem("expire_date", expire_date)
          callback(res.data);
        } else {
          callback("failed");
        }
      })
      .catch((err) => onRejected(err));
  },
  createAccount: (payload, callback, onRejected) => {
    api
      .post("/register", { ...payload, username: localStorage.getItem("username"), version: API_VERSION })
      .then((res) => {
        callback(res?.data)
      })
      .catch((err) => onRejected(err));
  },
  changePassword: (payload, callback, onRejected) => {
    api
      .post("/changepassword", { ...payload, username: localStorage.getItem("username"), version: API_VERSION })
      .then((res) => {
        callback(res?.data)
      })
      .catch((err) => onRejected(err));
  },
}

export const getDataAPI = (payload, callback, onRejected) => {
  payload = { ...payload, username: localStorage.getItem("username"), version: API_VERSION }
  api.post("/get_data_v2", payload)
    .then((res) => {
      if (payload.type == "Energy") {
        var arr = []
        var arr_preferred = []
        Object.keys(res.data.energy).forEach(key => {
          if (typeof res.data.energy[key] == "string" && res.data.energy[key].startsWith("https://")) {
            arr.push(key);
          }
        })
        Object.keys(res.data.energy_preferred).forEach(key => {
          if (typeof res.data.energy_preferred[key] == "string" && res.data.energy_preferred[key].startsWith("https://")) {
            arr_preferred.push(key);
          }
        })
        var count = 0
        arr_preferred.forEach(async item => {
          try{
            const energy = await fetch(res.data.energy_preferred[item])
            const energy_blob = await energy.blob()
            var objectURL = URL.createObjectURL(energy_blob);
            res.data.energy[item] = objectURL;
            count += 1
            if (count == arr.length) {
              callback(res?.data)
            }
          } catch {
            count = 0;
            const energy = await fetch(res.data.energy[item])
            const energy_blob = await energy.blob()
            var objectURL = URL.createObjectURL(energy_blob);
            res.data.energy[item] = objectURL;
            count += 1
            if (count == arr.length) {
              callback(res?.data)
            }
          }
          
        })
      }
      if (payload.type == "AQI") {
        let sites = ['outdoor', 'indoor']
        sites.forEach((site, site_index) => {
          var arr = []
          var arr_preferred = []
          Object.keys(res.data[site]).forEach(key => {
            if (typeof res.data[site][key] == "string" && res.data[site][key].startsWith("https://")) {
              arr.push(key)
            }
          })
          Object.keys(res.data[site+"_preferred"]).forEach(key => {
            if (typeof res.data[site+"_preferred"][key] == "string" && res.data[site+"_preferred"][key].startsWith("https://")) {
              arr_preferred.push(key)
            }
          })
          var count = 0
          arr_preferred.forEach(async (item) => {
            try {
              const aqi = await fetch(res.data[site+"_preferred"][item])
              const aqi_blob = await aqi.blob()
              var objectURL = URL.createObjectURL(aqi_blob);
              res.data[site][item] = objectURL;
              count += 1
              if (site_index == sites.length - 1 && count == arr.length) {
                callback(res?.data)
              }
            } catch {
              count = 0
              const aqi = await fetch(res.data[site][item])
              const aqi_blob = await aqi.blob()
              var objectURL = URL.createObjectURL(aqi_blob);
              res.data[site][item] = objectURL;
              count += 1
              if (site_index == sites.length - 1 && count == arr.length) {
                callback(res?.data)
              }
            }
          })
        })
      }
    })
    .catch((err) => onRejected(err));
}
export const getSetting = (callback, onRejected) => {
  api.post("/getsettings", { building: localStorage.getItem("building"), username: localStorage.getItem("username") })
    .then((res) => {
      callback(res?.data);
    })
    .catch((err) => onRejected(err));
}
export const updateSettings = (payload, callback, onRejected) => {
  api.post("/updatesettings", { ...payload, username: localStorage.getItem("username"), version: API_VERSION })
    .then((res) => {
      callback(res);
    })
    .catch((err) => onRejected(err));
}
export const getUploadLink = (callback, onRejected) => {
  api.post("/getpresigned", { building: localStorage.getItem("building"), username: localStorage.getItem("username") })
    .then((res) => {
      callback(res);
    })
    .catch((err) => onRejected(err));
}
export const uploadFile = (payload, callback, onRejected) => {
  uploadApi.put("/uploadfile", { ...payload, username: localStorage.getItem("username"), version: API_VERSION })
    .then((res) => {
      callback(res);
    })
    .catch((err) => onRejected(err));
}
export const uploadSvg = (payload, callback, onRejected) => {
  uploadApi.put("uploadsvg", { ...payload, username: localStorage.getItem("username"), version: API_VERSION })
    .then((res) => {
      callback(res);
    })
    .catch((err) => onRejected(err));
}

// Get Data QSNCC
export const getDataQsnccAPI = (payload, callback, onRejected) => {
  payload = { ...payload, username: localStorage.getItem("username"), version: API_VERSION }
  api.post("/get_data_qsncc", payload)
    .then((res) => {
      let sites = ['outdoor', 'indoor']
      sites.forEach((site, site_index) => {
        var arr = []
        Object.keys(res.data[site]).forEach(key => {
          if (typeof res.data[site][key] == "string" && res.data[site][key].startsWith("https://")) {
            arr.push(key)
          }
        })
        var count = 0
        arr.forEach((item) => {
          fetch(res.data[site][item])
            .then(res => res.blob())
            .then(function (myBlob) {
              var objectURL = URL.createObjectURL(myBlob);
              res.data[site][item] = objectURL;
              count += 1
              if (site_index == sites.length - 1 && count == arr.length) {
                callback(res?.data)
              }
            })
        })
      })
    })
    .catch((err) => onRejected(err));
}

export const getDataSDMCAPI = (payload, callback, onRejected) => {
  payload = { ...payload, username: localStorage.getItem("username"), version: API_VERSION }
  api.post("/get_data_sdmc", payload)
    .then((res) => {
      let sites = ['outdoor', 'indoor']
      sites.forEach((site, site_index) => {
        var arr = []
        Object.keys(res.data[site]).forEach(key => {
          if (typeof res.data[site][key] == "string" && res.data[site][key].startsWith("https://")) {
            arr.push(key)
          }
        })
        var count = 0
        arr.forEach((item) => {
          fetch(res.data[site][item])
            .then(res => res.blob())
            .then(function (myBlob) {
              var objectURL = URL.createObjectURL(myBlob);
              res.data[site][item] = objectURL;
              count += 1
              if (site_index == sites.length - 1 && count == arr.length) {
                callback(res?.data)
              }
            })
        })
      })
    })
    .catch((err) => onRejected(err));
}
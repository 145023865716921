import React, { useEffect, useState } from "react";
import Logo from "../assets/icons/logo_color.svg"
import { authApi } from "../services/api";
import { redirectTo } from "../services/helper";

export default function ChangePassword() {
    const [isAlert, setIsAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    const handleChangePassword = () => {
        let password = document.getElementById("password").value
        let new_password = document.getElementById("new_password").value
        let confirm_password = document.getElementById("confirm_password").value
        if (password == "" || new_password == "" || new_password != confirm_password) {
            setAlertTitle("Invalid Password")
            setIsAlert(true)
        } else {
            var payload = {
                user_id: parseInt(localStorage.getItem("user_id")),
                old_password: password,
                new_password: new_password,
            }
            authApi.changePassword(
                payload,
                (res) => {
                    if (res?.status == "success") {
                        document.getElementById("password").value = ""
                        document.getElementById("new_password").value = ""
                        document.getElementById("confirm_password").value = ""
                    }
                    setAlertTitle(res?.message)
                    setIsAlert(true)
                },
                (err) => { console.log(err) })
        }
    }
    useEffect(() => {
        let userId = localStorage.getItem("user_id")
        if (userId == null || userId == undefined) {
            redirectTo("/")
        }
    }, [])
    return (
        <div class="d-flex flex-column justify-content-center align-items-center bg-dark-cyan" style={{ width: "100vw", height: "100vh" }}>
            <img class="mb-4" alt="logo" src={Logo} width="400px" />
            <h3 class="text-white mb-4">Change Password</h3>
            <div style={{ height: "3rem" }}>
                {isAlert && <div class="alert alert-warning p-1 px-3 text-capitalize" role="alert" style={{ fontSize: "0.8rem" }}>
                    {alertTitle}
                </div>}
            </div>
            <div class="d-flex flex-column justify-content-start" style={{ width: "500px", color: "#FFFFFF" }}>
                <form style={{ width: "100%" }}>
                    <div class="form-group row mb-3">
                        <label class="col-sm-4 col-form-label" for="password">Old Password</label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control" id="password" placeholder="Password" required />
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-sm-4 col-form-label" for="new_password">New Password</label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control" id="new_password" placeholder="New Password" required />
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-sm-4 col-form-label" for="confirm_password">Confirm Password</label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control" id="confirm_password" placeholder="Confirm Password" required />
                        </div>
                    </div>
                </form>
                <div class="w-100 d-flex justify-content-end">
                    <button class="btn btn-outline-light" onClick={handleChangePassword}>Submit</button>
                </div>
                <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { redirectTo } from "../services/helper";
import BannerAQI from "./banner_aqi";
import BannerEnergy from "./banner_energy";

export default function MainBanner(props) {
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        if (!localStorage.getItem("building")) redirectTo("/")
        const interval = setInterval(() => {
            setToggle(toggle => !toggle)
        }, props.interval);
        return () => clearInterval(interval);
    }, [])
    return (
        <div class="d-flex flex-column overflow-hidden bg-black">
            <div style={{ height: toggle ? "100%": "0px", visibility: toggle ? "visible":"hidden" }}>
                <BannerAQI />
            </div>
            <div style={{ height: !toggle ? "100%": "0px", visibility: !toggle ? "visible":"hidden" }}>
                <BannerEnergy />
            </div>
        </div>
    )
}

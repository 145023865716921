import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BannerAQI from './views/banner_aqi'
import BannerEnergy from './views/banner_energy'
import BottomBarEnergy from './views/bottombar_energy'
import BuildingSetting from "./views/building_setting";
import SignIn from "./views/sign_in";
import MainBanner from "./views/main_banner";
import BottomBarAQI from "./views/bottombar_aqi";
import MainBottomBarTV from "./views/main_tv";
import CreateAccount from "./views/create_account";
import { getSetting } from "./services/api";
import TestFullHD from "./views/test";
import ChangePassword from "./views/change_password";
import QNSCC from "./views/qnscc";
import RAMA from "./views/rama";

function App() {
  const [hasData, setHasData] = useState(false);
  const [timeInterval, setTimeInterval] = useState(60000);

  useEffect(() => {
    // getSetting((res) => {
    //   if(res?.switch_interval){
    //     setTimeInterval(res?.switch_interval * 60000)
    //   }
    //   setHasData(true)
    // }, (err) => {
    //   console.log(err)
    // })
    setHasData(true)
  }, [])
  return (
    <div className="App">
      {window.location.pathname == "/banner" && hasData && <MainBanner interval={timeInterval} />}
      {window.location.pathname == "/tv" && hasData && <MainBottomBarTV interval={timeInterval} />}
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <SignIn />
          </Route>
          <Route exact path="/banner/aqi">
            <BannerAQI />
          </Route>
          <Route exact path="/banner/energy">
            <BannerEnergy />
          </Route>
          <Route exact path="/tv/energy">
            <BottomBarEnergy />
          </Route>
          <Route exact path="/tv/aqi">
            <BottomBarAQI />
          </Route>
          <Route exact path="/setting">
            <BuildingSetting />
          </Route>
          <Route exact path="/admin">
            <CreateAccount />
          </Route>
          <Route exact path="/change-passwd">
            <ChangePassword />
          </Route>
          <Route exact path="/public-qsncc">
            <QNSCC />
          </Route>
          <Route exact path="/sdmc-rama">
            <RAMA />
          </Route>
          <Route exact path="/test">
            <TestFullHD />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";
import LocationIcon from "../assets/icons/icon_sdmc_rama/location.svg"
import TempIcon from "../assets/icons/icon_sdmc_rama/temp.svg"
import HumidityIcon from "../assets/icons/icon_sdmc_rama/humidity.svg"
import IonIcon from "../assets/icons/icon_sdmc_rama/ion.svg"
import TvocIcon from "../assets/icons/icon_sdmc_rama/tvoc.svg"
import Pm25Icon from "../assets/icons/icon_sdmc_rama/pm25.svg"
import Co2Icon from "../assets/icons/icon_sdmc_rama/co2.svg"
import AqiGoodFace from "../assets/icons/icon_sdmc_rama/aqi_1.svg"
import AqiModerateFace from "../assets/icons/icon_sdmc_rama/aqi_2.svg"
import AqiSensitiveFace from "../assets/icons/icon_sdmc_rama/aqi_3.svg"
import AqiUnHealthyFace from "../assets/icons/icon_sdmc_rama/aqi_4.svg"
import AqiVeryUnHealthyFace from "../assets/icons/icon_sdmc_rama/aqi_5.svg"
import AqiHazadousFace from "../assets/icons/icon_sdmc_rama/aqi_6.svg"
import AqiGoodBand from "../assets/icons/icon_sdmc_rama/aqi_band_1.svg"
import AqiModerateBand from "../assets/icons/icon_sdmc_rama/aqi_band_2.svg"
import AqiSensitiveBand from "../assets/icons/icon_sdmc_rama/aqi_band_3.svg"
import AqiUnHealthyBand from "../assets/icons/icon_sdmc_rama/aqi_band_4.svg"
import AqiVeryUnHealthyBand from "../assets/icons/icon_sdmc_rama/aqi_band_5.svg"
import AqiHazadousBand from "../assets/icons/icon_sdmc_rama/aqi_band_6.svg"
import Logo from "../assets/icons/icon_sdmc_rama/Rama_Logo_Color-01.png"

import { numberWithCommas } from "../services/helper";
import { getDataSDMCAPI } from "../services/api";
import TestBgIndoor from "../assets/icons/icon_sdmc_rama/indoor_green.mp4"
import TestBgOutdoor from "../assets/icons/icon_sdmc_rama/outdoor_green.mp4"
import TestBgIndoorJpg from "../assets/icons/icon_sdmc_rama/indoor_good.jpg"
import TestBgOutdoorJpg from "../assets/icons/icon_sdmc_rama/outdoor_good.jpg"


function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}
function getAqiFace(level) {
    if (level == "good") return AqiGoodFace
    if (level == "moderate") return AqiModerateFace
    if (level == "unhealthy for sensitive groups") return AqiSensitiveFace
    if (level == "unhealthy") return AqiUnHealthyFace
    if (level == "very unhealthy") return AqiVeryUnHealthyFace
    if (level == "hazadous") return AqiHazadousFace
    return ""
}
function getAqiBand(level) {
    if (level == "good") return AqiGoodBand
    if (level == "moderate") return AqiModerateBand
    if (level == "unhealthy for sensitive groups") return AqiSensitiveBand
    if (level == "unhealthy") return AqiUnHealthyBand
    if (level == "very unhealthy") return AqiVeryUnHealthyBand
    if (level == "hazadous") return AqiHazadousBand
    return ""
}
function getAqiTextFontSize(level, site) {
    if (level == "very unhealthy") return site == "indoor" ? "97px" : "50px"
    return site == "indoor" ? "120px" : "56px"
}
// const mock_res = {
//     indoor: {
//         location_name: "Plenary Hall",
//         background: TestBgIndoorJpg,
//         temp: 25,
//         humidity: 55,
//         aqi_value: 15,
//         aqi_text: "Good",
//         ion_value: 120000,
//         ion_text: "   The higher ions,   the better air quality.",
//         pm25_value: 10,
//         pm25_text: "Good",
//         co2_value: 100,
//         co2_text: "Good",
//         tvoc_value: 10,
//         tvoc_text: "Good"
//     },
//     outdoor: {
//         location_name: "",
//         background: TestBgOutdoorJpg,
//         temp: 26,
//         humidity: 60,
//         aqi_value: 15,
//         aqi_text: "Good",
//         ion_value: 120000,
//         ion_text: "",
//         pm25_value: 10,
//         pm25_text: "Good",
//         co2_value: 100,
//         co2_text: "Good"
//     }
// }

export default function RAMA() {
    const [data, setData] = useState(null);

    const outdoorVideoRef = useRef()
    const indoorVideoRef = useRef()

    const VIDEO_WIDTH = 1080 + 10

    const loadData = () => {
        getDataSDMCAPI({}, (res) => {
            setData(res)
            outdoorVideoRef.current.src = res?.outdoor?.background
            indoorVideoRef.current.src = res?.indoor?.background
        }, (err) => {
            console.log(err)
        });

    }

    useEffect(() => {
        loadData()
        const interval = setInterval(loadData, 60000);
        return () => clearInterval(interval)
    }, [])

    return (
        <>
            {data && <div class="qnscc-font" style={{
                width: "100%",
                minWidth: "1080px",
                height: "1300px",
                display: "flex",
                flexDirection: "row",
                background: "#010101"
            }}>
                <div style={{ width: "100%", color: "#ffffff" }}>
                    <div class="row m-0 p-0 w-100" style={{ height: "72.5%", overflow: "hidden" }}>
                        <div id="indoor" class="m-0 p-0" style={{ width: "100%" }}>
                            <div class="w-100 d-flex justify-content-between align-items-center" style={{ height: "76px", padding: "0 2.25rem", position: "absolute", background: "rgba(0, 0, 0, 0.53)", fontSize: "40px" }}>
                                <div>Air Quality Dashboard</div>
                                <div><img alt="" src={Logo} height="52" /> SDMC</div>
                            </div>
                            <div class="row w-100 d-flex flex-column m-0" style={{ textAlign: "start", paddingLeft: "2.25rem", paddingRight: "2.25rem", paddingTop: "86px", position: "absolute" }}>
                                <div id="indoor-header-box" class="w-100 d-flex justify-content-between align-items-center p-0">
                                    <div id="indoor-header" style={{ fontSize: 52, lineHeight: "50px" }}>Indoor Air Quality</div>
                                    <div class="d-flex">
                                        <div id="indoor-temp" class="d-flex px-3" style={{ fontSize: "36px", lineHeight: "45px" }}>
                                            <img class="px-2" alt="" src={TempIcon} height="45" />
                                            <div>{data.indoor.temp} °c</div>
                                        </div>
                                        <div id="indoor-humidity" class="d-flex" style={{ fontSize: "36px", lineHeight: "45px" }}>
                                            <img class="px-2" alt="" src={HumidityIcon} height="45" />
                                            <div>{data.indoor.humidity} %</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div id="indoor-loc" class="d-flex p-0" style={{ paddingTop: "13px" }}>
                                    <img alt="" src={LocationIcon} height="37" />
                                    <span style={{ fontSize: "42px", marginTop: "-10px", marginLeft: "13px" }}>{data.indoor.location_name}</span>
                                </div> */}
                                <div id="indoor-aqi" class="d-flex justify-content-between align-items-center mx-auto" style={{ width: "70%" }}>
                                    <img alt="" src={getAqiFace(data.indoor.aqi_text.toLowerCase())} height="280" style={{ marginLeft: "-54px" }} />
                                    <div id="indoor-aqi-detail" class="d-flex flex-column" style={{ lineHeight: "90px" }}>
                                        <div style={{ fontSize: getAqiTextFontSize(data.indoor.aqi_text.toLowerCase(), "indoor"), marginRight: "-40%" }}>
                                            {data.indoor.aqi_text.toLowerCase() == "unhealthy for sensitive groups" ? "UNHEALTHY" : data.indoor.aqi_text.toUpperCase()}
                                            {data.indoor.aqi_text.toLowerCase() == "unhealthy for sensitive groups" && <div class="mx-2" style={{ fontSize: "40px", marginTop: "-30px" }}>For sensitive groups</div>}
                                        </div>
                                        <div id="indoor-aqi-value" class="d-flex mb-2">
                                            <div style={{ fontSize: "60px", width: "100px" }}>AQI</div>
                                            <div style={{ fontSize: "90px" }}>{numberWithCommas(data.indoor.aqi_value)}</div>
                                        </div>
                                        <img alt="" src={getAqiBand(data.indoor.aqi_text.toLowerCase())} height="50" />
                                    </div>
                                </div>
                                <div id="indoor-ion"  style={{ width: "70%", height: "80px" }}>
                                    {/* <div id="indoor-ion-title" class="d-flex" style={{ height: "100px" }}>
                                        <div class="d-flex h-100 justify-content-center align-items-center " style={{ width: "30%", fontSize: "38px", lineHeight: "45px" }}>
                                            <img alt="" src={IonIcon} height="75" />
                                            <div>Ion</div>
                                        </div>
                                        <div class="d-flex flex-column h-100 justify-content-center align-items-center " style={{ width: "40%", fontSize: "72px", lineHeight: "60px" }}>
                                            <div>{numberWithCommas(data.indoor.ion_value)}</div>
                                            <div style={{ fontSize: "20px", lineHeight: "24px" }}>ions/cm<sup>3</sup></div>
                                        </div>
                                        <div class="h-100 text-center d-flex align-items-center" style={{ width: "30%", fontSize: "30px", lineHeight: "28px", wordWrap: "break-word", whiteSpace: "break-spaces" }}>
                                            {data.indoor.ion_text}
                                        </div>
                                    </div>
                                    <div style={{ height: "30px", fontSize: "20px" }}>The ion quality is reflected by the air quality. The higher the ion quantity, the higher possibility of better air quality.</div> */}
                                </div>
                                <div class="d-flex justify-content-between p-0 mx-auto mt-1" style={{ width: "70%", height: "200px" }}>
                                    <div id="indoor-tvoc" class="qsncc-box h-100 d-flex flex-column text-center" style={{ width: "32.8%" }}>
                                        <div id="indoor-tvoc-title" class="d-flex pt-2" style={{ height: "70px" }}>
                                            <div class="w-100 d-flex justify-content-center align-items-center " style={{ fontSize: "30px", lineHeight: "40px" }}>
                                                <img alt="" src={TvocIcon} height="55" />
                                                <div>tVOC</div>
                                            </div>
                                        </div>
                                        <div class="w-100" style={{ fontSize: "36px", lineHeight: "32px" }}>{data.indoor.tvoc_text}</div>
                                        <div class="w-100 pt-1" style={{ fontSize: "38px", lineHeight: "38px" }}>{numberWithCommas(data.indoor.tvoc_value)}</div>
                                        <div class="w-100" style={{ fontSize: "24px", lineHeight: "20px" }}>mg/m<sup>3</sup></div>
                                    </div>
                                    <div id="indoor-pm25" class="qsncc-box h-100 d-flex flex-column text-center" style={{ width: "32.8%" }}>
                                        <div id="indoor-pm25-title" class="d-flex pt-2" style={{ height: "70px" }}>
                                            <div class="w-100 d-flex justify-content-center align-items-center " style={{ fontSize: "30px", lineHeight: "40px" }}>
                                                <img alt="" src={Pm25Icon} height="55" />
                                                <div>PM 2.5</div>
                                            </div>
                                        </div>
                                        <div class="w-100" style={{ fontSize: "36px", lineHeight: "32px" }}>{data.indoor.pm25_text}</div>
                                        <div class="w-100 pt-1" style={{ fontSize: "38px", lineHeight: "38px" }}>{numberWithCommas(data.indoor.pm25_value)}</div>
                                        <div class="w-100" style={{ fontSize: "24px", lineHeight: "20px" }}>ug/m<sup>3</sup></div>
                                    </div>
                                    <div id="indoor-co2" class="qsncc-box h-100 d-flex flex-column text-center" style={{ width: "32.8%" }}>
                                        <div id="indoor-co2-title" class="d-flex pt-2" style={{ height: "70px" }}>
                                            <div class="w-100 d-flex justify-content-center align-items-center " style={{ fontSize: "30px", lineHeight: "40px" }}>
                                                <img alt="" src={Co2Icon} height="55" />
                                                <div>CO<sub>2</sub></div>
                                            </div>
                                        </div>
                                        <div class="w-100" style={{ fontSize: "36px", lineHeight: "32px" }}>{data.indoor.co2_text}</div>
                                        <div class="w-100 pt-1" style={{ fontSize: "38px", lineHeight: "38px" }}>{numberWithCommas(data.indoor.co2_value)}</div>
                                        <div class="w-100" style={{ fontSize: "24px", lineHeight: "20px" }}>ppm</div>
                                    </div>
                                </div>
                            </div>
                            <img alt="indoor-background" ref={indoorVideoRef} src="" width={VIDEO_WIDTH} />
                            {/* <video ref={indoorVideoRef} autoPlay loop muted width={VIDEO_WIDTH} >
                                <source src={TestBgIndoor} type="video/mp4" />
                            </video> */}
                        </div>
                    </div>
                    <div class="row m-0 p-0" style={{ width: "100%", height: "27.5%", overflow: "hidden" }}>
                        <div id="outdoor" class="p-0" style={{ width: "100%" }}>
                            <div class="row w-100 d-flex flex-column m-0" style={{ textAlign: "start", paddingLeft: "2.25rem", paddingRight: "2.25rem", paddingTop: "10px", position: "absolute" }}>
                                <div id="outdoor-header-box" class="d-flex justify-content-between align-items-center p-0">
                                    <div id="outdoor-header" style={{ fontSize: 52, lineHeight: "50px" }}>Outdoor Air Quality</div>
                                    <div class="d-flex">
                                        <div id="outdoor-temp" class="d-flex px-3" style={{ fontSize: "36px", lineHeight: "45px" }}>
                                            <img class="px-2" alt="" src={TempIcon} height="45" />
                                            <div>{data.outdoor.temp} °c</div>
                                        </div>
                                        <div id="outdoor-humidity" class="d-flex" style={{ fontSize: "36px", lineHeight: "45px" }}>
                                            <img class="px-2" alt="" src={HumidityIcon} height="45" />
                                            <div>{data.outdoor.humidity} %</div>
                                        </div>
                                    </div>
                                </div>
                                <div id="outdoor-content" class="d-flex justify-content-start mx-auto p-0" style={{ marginTop: "-10px", width: "90%" }}>
                                    <div id="outdoor-aqi" class="d-flex pt-2 justify-content-start align-items-center" style={{ width: "70%" }}>
                                        <img alt="" src={getAqiFace(data.outdoor.aqi_text.toLowerCase())} height="180" style={{ marginLeft: "-30px" }} />
                                        <div id="outdoor-aqi-detail" class="d-flex flex-column" style={{ marginLeft: "20px" }}>
                                            <div style={{ fontSize: getAqiTextFontSize(data.outdoor.aqi_text.toLowerCase(), "outdoor"), marginRight: "-40%" }}>
                                                {data.outdoor.aqi_text.toLowerCase() == "unhealthy for sensitive groups" ? "UNHEALTHY" : data.outdoor.aqi_text.toUpperCase()}
                                                {data.outdoor.aqi_text.toLowerCase() == "unhealthy for sensitive groups" && <div style={{ fontSize: "25px", marginTop: "-30px" }}>For sensitive groups</div>}
                                            </div>
                                            <div id="outdoor-aqi-value" class="d-flex mb-3" style={{ lineHeight: "42px" }}>
                                                <div style={{ fontSize: "26px", width: "60px" }}>AQI</div>
                                                <div style={{ fontSize: "42px" }}>{numberWithCommas(data.outdoor.aqi_value)}</div>
                                            </div>
                                            <img alt="" src={getAqiBand(data.outdoor.aqi_text.toLowerCase())} height="40" style={{ marginLeft: "-55px" }} />
                                        </div>
                                    </div>
                                    <div style={{ width: "40%" }}>
                                        <div id="outdoor-ion"  style={{ height: "80px" }}>
                                            {/* <div id="outdoor-ion-title" class="d-flex flex-column h-100" >
                                                <div class="d-flex justify-content-between align-items-center" style={{ height: "70px", fontSize: "36px" }}>
                                                    <img alt="" src={IonIcon} height="60" style={{ marginLeft: "-15px" }} />
                                                    <div style={{ marginLeft: "-8px" }}>Ion</div>
                                                    <div style={{ marginLeft: "8px", fontSize: "42px", marginTop: "-5px" }}>{numberWithCommas(data.outdoor.ion_value)}</div>
                                                </div>
                                                <div class="d-flex flex-column h-100 justify-content-center align-items-center pl-4" style={{ marginTop: "-20px" }} >
                                                    <div class="w-100 d-flex justify-content-end" style={{ fontSize: "20px", lineHeight: "6px" }}>ions/cm<sup>3</sup></div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div class="d-flex justify-content-between p-0 mx-auto mt-1" style={{ height: "190px" }}>
                                            <div id="outdoor-pm25" class="qsncc-box h-100 d-flex flex-column text-center" style={{ width: "50%" }}>
                                                <div id="outdoor-pm25-title" class="d-flex" style={{ height: "70px" }}>
                                                    <div class="w-100 d-flex justify-content-center align-items-center " style={{ fontSize: "26px", lineHeight: "40px" }}>
                                                        <img alt="" src={Pm25Icon} height="45" />
                                                        <div>PM 2.5</div>
                                                    </div>
                                                </div>
                                                <div class="w-100" style={{ fontSize: "36px", lineHeight: "32px" }}>{data.outdoor.pm25_text}</div>
                                                <div class="w-100 pt-1" style={{ fontSize: "38px", lineHeight: "30px" }}>{numberWithCommas(data.outdoor.pm25_value)}</div>
                                                <div class="w-100 pb-2" style={{ fontSize: "24px", lineHeight: "20px" }}>ug/m<sup>3</sup></div>
                                            </div>
                                            <div id="outdoor-co2" class="qsncc-box h-100 d-flex flex-column text-center" style={{ width: "50%" }}>
                                                <div id="outdoor-co2-title" class="d-flex" style={{ height: "70px" }}>
                                                    <div class="w-100 d-flex justify-content-center align-items-center " style={{ fontSize: "26px", lineHeight: "40px" }}>
                                                        <img alt="" src={Co2Icon} height="45" />
                                                        <div>CO<sub>2</sub></div>
                                                    </div>
                                                </div>
                                                <div class="w-100" style={{ fontSize: "36px", lineHeight: "32px" }}>{data.outdoor.co2_text}</div>
                                                <div class="w-100 pt-1" style={{ fontSize: "38px", lineHeight: "30px" }}>{numberWithCommas(data.outdoor.co2_value)}</div>
                                                <div class="w-100 pb-2" style={{ fontSize: "24px", lineHeight: "20px" }}>ppm</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img alt="outdoor-background" ref={outdoorVideoRef} src="" width={VIDEO_WIDTH} />

                            {/* <video ref={outdoorVideoRef} autoPlay loop muted width={VIDEO_WIDTH}>
                                <source src={TestBgOutdoor} type="video/mp4" />
                            </video> */}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
import React, { useEffect, useRef } from "react";

export default function BgVideo(props) {
    const videoRef = useRef()

    useEffect(()=>{
        videoRef.current.src = props.source
    },[props])
    return (
        <video ref={videoRef} autoPlay muted style={{height: props.bottom_bar ? "200px" : "242px"}}>
            <source src="" type="video/mp4" />
        </video>
    );
}